import * as React from "react";
import "@fontsource/dm-sans";
import "./index.sass";
import Layout from "../components/Layout";
import Hero from "../components/Hero";
import Domaines from "../components/Domaines";
import Simplicite from "../components/Simplicite";
import QuickActions from "../components/QuickActions";
import QuickAbo from "../components/QuickAbo";
import QuickContract from "../components/QuickContract";

import { graphql } from "gatsby";

// markup
const IndexPage = ({ data }) => {
  return (
    <Layout>
      <div id="indexpage">
        <Hero></Hero>
        <Domaines
          domains={data.allDomainsJson.edges.map((e) => {
            return {
              title: e.node.cardTitle,
              text: e.node.cardText,
              slug: e.node.slug,
            };
          })}
        ></Domaines>
        <div id="home-actions">
          <QuickActions></QuickActions>
          <QuickContract></QuickContract>
          <QuickAbo></QuickAbo>
        </div>

        <Simplicite />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allDomainsJson {
      edges {
        node {
          slug
          cardTitle
          cardText
        }
      }
    }
  }
`;

export default IndexPage;
